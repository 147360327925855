<template>
    <div class="view app">
        <div class="header">
            <div class="navbar-main-bg"></div>
            <nav class="navbar navbar-main navbar-dark">
                <v-container>
                    <v-row class="main_header justify-content-center">
                        <v-col cols="3" class="logo no-padd-top no-padd-bott">
                            <router-link to="/" class="navbar-brand">
                                <img src="/svg/logo_small.svg" width="30" height="30" class="d-inline-block align-top icon" alt="">
                                <div class="text">
                                    R2A Admin
                                </div>
                            </router-link>
                        </v-col>
                        <v-col cols="6" class="menu_container text-center no-padd-top no-padd-bott">
                            <v-row class="no-margin">
                                <v-col cols="3" class="menu_perspective no-padd-top no-padd-bott">
                                   <v-menu nudge-right='0px' content-class="custom_menu_perspective" offset-y right max-width='190' data-aos="fade" v-if="currentPerspective">
                                        <template v-slot:activator="{ on, attrs }">
                                            <button class="btn_menu" v-bind="attrs" v-on="on">
                                               Perspective: {{currentPerspective.name}}
                                            </button>
                                        </template>
                                        <v-list>
                                            <router-link v-for="(item, index) in perspectives" :key="index" :to="'/panel/'+item.name.toLowerCase()">
                                                <v-list-item v-ripple @click="setPerspective(item)">
                                                    <v-list-item-title>{{ item.name }} {{item.type}}</v-list-item-title>
                                                </v-list-item>
                                            </router-link>
                                        </v-list>
                                    </v-menu>
                                </v-col>
                                <v-col cols="6" class="search_input no-padd-top no-padd-bott">
                                    <input placeholder="Search">
                                    <img v-ripple class="float-right" src="/svg/search.svg">
                                </v-col>
                                <v-col cols="3" class="no-padd">
                                    <v-menu nudge-left="0px" content-class="custom_menu">
                                        <template v-slot:activator="{ on, attrs }">
                                            <button v-bind="attrs" v-on="on" class="btn_menu">Menu: {{menuLocation}}</button>
                                        </template>
                                        <v-list v-if="currentPerspective !==  null">
                                            <router-link v-for="(item, index) in currentPerspective.children" :key="index" :to="'/panel/'+currentPerspective.name.toLowerCase()+'/'+item.name.toLowerCase()">
                                                <v-list-item v-ripple @click="setMenuLocation(item)">
                                                    <v-list-item-title>
                                                        {{ item.name }}
                                                    </v-list-item-title>
                                                </v-list-item>
                                            </router-link>
                                        </v-list>
                                    </v-menu>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="3" class="account_container no-padd-top no-padd-bott">
                            <div class="float-right">
                                <span class='version_id' data-aos="fade" v-if="systemVersion">Tech System - {{systemVersion.version}}</span>
                                <span class="vert_line"></span>
                                <span class="logged_user">{{loggedUser}}</span>
                                <a @click="logout" class="nav-link" href="#">Logout</a>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </nav>
        </div>
        <div class="content">
            <router-view></router-view>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
    data() {
        return {
            systemVersion: null,
            perspectives: null, //populate perspectives menu
            currentPerspective: null, // current view perspective
            menuLocation:null, 

            loggedUser: 'User User'
        }
    },
    mounted() {
        this.getPerspectives(),
            this.getSystemVersion()
    },
    watch: {
        $route(to, from) {
            this.refreshPerspective();
        }
    },
    methods: {
        ...mapActions(['logout']),
        getPerspectives() {
            axios.get('backend/views')
                .then(res => {
                    this.perspectives = res.data;
                    this.refreshPerspective();
                })

        },
        getSystemVersion() {
            var self = this;
            axios.get('backend/info')
                .then(function(res) {
                    self.systemVersion = res.data;
                })
        },
        setPerspective(item) {
            this.currentPerspective = item;
            //console.log(this.currentPerspective);

        },
        setMenuLocation(item) {
            this.menuLocation = item.name;
            //console.log(this.currentPerspective);

        },
        refreshPerspective() {
            let routeName = this.$route.name;

            for (let i = 0; i < this.perspectives.length; i++) {
                if (routeName.includes(this.perspectives[i].name)) {
                    this.currentPerspective = this.perspectives[i];
                    
                    if(this.currentPerspective.children.length>0){
                        for(let j=0; j < this.currentPerspective.children.length;j++){
                            if(routeName.includes(this.currentPerspective.children[j].name)){
                                this.menuLocation = this.currentPerspective.children[j].name;
                                break;
                            }
                            else {
                                this.menuLocation = null;
                            }
                            
                        }
                    }
                    else {
                        this.menuLocation = null;
                    }

                }
            }
        }
    }
}
</script>